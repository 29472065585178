import './App.css';
import {Router, Route, Routes } from 'react-router-dom';
import Faqs from './component/Faqs';
import Contact from './component/Contact';
import Main from './component/Main';

function App() {
  return (
    <div >
      <Routes>
        <Route path='/' element={<Main/>} />
        <Route path='/Faqs' element={<Faqs />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </div >
  );
}

export default App;
