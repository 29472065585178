import React from 'react'

function Section3() {
    return (
        <div style={{ backgroundColor: "rgb(249, 245, 234)", marginTop: "-5vh" }}>
            <div style={{ margin: "10% 7% 0% 7%" }}>
                <div className='text-start' style={{ color: "#441a05" }}>COMMUNITY HOPE</div>

                <div className='d-flex'>
                    <div className='text-start flex-grow-1 fw-semibold' style={{ color: "#441a05", fontSize: "7vh" }}>Your Contribution Matters</div>
                    <div className="d-flex align-items-center View-More">
                        <div className='fs-5' style={{ color: "#441a05" }}><span>View More </span></div>
                        <div>
                            <i className="fa-regular fa-circle-right mx-3 fa-2x" style={{ color: "#441a05" }}></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-around Card' style={{margin:"35px 100px"}}>
                <div className="mx-3">
                    <div className="card">
                        <img src="./image/mission1.jpg" className="card-img-top" />
                        <div className="card-body card-content  text-start">
                            <button className="card-btn my-4" style={{ border: "none", backgroundColor: "#daa23d", color: "white", padding:"2% 6%"}}>ISLAM</button>
                            <h5 className="card-title fs-4 fw-semibold" style={{ color: "#441a05" }}>Aid For Refugees</h5>
                            <p className="card-text" style={{ color: "grey" }}>Some quick example text to build on the card title and make up
                                the bulk of the card's content.</p>
                            <div><progress value="50" max="100" style={{ backgroundColor: "green", width: "50vh" }}></progress></div>
                            <div className="d-flex">
                                <p>Raised: $2,880</p>
                                <p style={{ marginLeft: "20vh" }}>Goal: $7,000</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-3">
                    <div className="card">
                        <img src="./image/mission2.jpg" className="card-img-top" />
                        <div className="card-body card-content  text-start">
                            <button className="card-btn my-4" style={{ border: "none", backgroundColor: "#daa23d", color: "white", padding:"2% 6%" }}>ISLAM</button>
                            <h5 className="card-title fs-4 fw-semibold" style={{ color: "#441a05" }}>Build Islamic Schools</h5>
                            <p className="card-text" style={{ color: "grey" }}>Some quick example text to build on the card title and make up
                                the bulk of the card's content.</p>
                            <div><progress value="50" max="100" style={{ backgroundColor: "green", width: "50vh" }}></progress></div>
                            <div className="d-flex">
                                <p>Raised: $2,880</p>
                                <p style={{ marginLeft: "20vh" }}>Goal: $7,000</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-3">
                    <div className="card">
                        <img src="./image/mission3.jpg" className="card-img-top" />
                        <div className="card-body card-content  text-start">
                            <button className="card-btn my-4" style={{ border: "none", backgroundColor: "#daa23d", color: "white", padding:"2% 6%" }}>ISLAM</button>
                            <div className="card-title fs-4 fw-semibold" style={{ color: "#441a05" }}>For Humanitarians</div>
                            <p className="card-text" style={{ color: "grey" }}>
                                Some quick example text to build on the card title and make up
                                the bulk of the card's content. </p>
                            <div><progress value="50" max="100" style={{ backgroundColor: "green", width: "50vh" }}></progress></div>
                            <div className="d-flex">
                                <p>Raised: $2,880</p>
                                <p style={{ marginLeft: "20vh" }}>Goal: $7,000</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Section3