import React from 'react'
import { Link } from "react-router-dom";
import './Style.css'

function Navbar() {
  return (
    <div className='position-relative d-flex justify-content-center'>

      <nav className="navbar navbar-expand-lg position-absolute" style={{ background: 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))', zIndex: "1", width: "100%" }}>
        <div className="container-fluid" style={{ marginLeft: "8%" }}>
          <img src="./image/Logo-Islam.webp" alt="logo" />
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginLeft: "9%" }}>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 my-4">
              <li className="nav-item">
                <Link className="nav-link fs-5 mx-2" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-5 mx-2" to="/Faqs">Faqs</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link fs-5 mx-2" to="#">Blog</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-5 mx-2" to="#">Donaton</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-5 mx-2" to="#">Shop</Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link fs-5 mx-2" to="/contact">Contact</Link>
              </li>
            </ul>
            <div className="btn border-success green-btn">search</div>
          </div>
        </div>
      </nav>

      <img className='position-absolute img-fluid w-100' src='./image/backgroundimage.jpg' alt="image" style={{ height: "650px", filter: "brightness(80%)" }} />

      <div className='d-flex justify-content-center'>
        <img className='position-absolute' src="./image/53_allah-word.webp" alt="allah" style={{ marginTop: "80px" }} />
        <div className='position-absolute text-light fw-semibold lh-1 Allah-text' style={{ marginTop: "150px", fontSize: "50px", width: "50%" }} > And Allah Invites To The Home of Peace</div>
        <button className='position-absolute head-btn text-light fw-bolder' style={{ backgroundColor: "#db9e30", marginTop: "270px", width: "250px", padding: "20px", border: "none" }}> BECOME A MEMBER</button>
        <div className='position-absolute scrol-head text-light fw-semibold' style={{ marginTop: "350px", width: "20%" }}> Scroll Down</div>
        <div className='position-absolute scrol-i-head text-light fw-semibold' style={{ marginTop: "400px", width: "20%" }}> <i className="fa-solid fa-circle-down fa-2x" style={{ color: "white" }}></i></div>
      </div>

    </div >
  )
}

export default Navbar