import React from 'react'
import Navbar from './Navbar';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import SectionFOOT from './SectionFOOT';

function Main() {
    return (
        <div className='App' style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '180vh'
        }}>
            <Navbar />
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
            <SectionFOOT />
        </div>
    )
}

export default Main