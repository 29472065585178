import React from 'react'
import './Style.css'

function Section6() {
    return (
        <div>
            <img src="./image/decor-layer-lower.webp" alt="decor" style={{ marginTop: "-7%", width: "100%" }} />
            <div style={{ margin: "1% 7% 2% 9%" }}>
                <div className='text-start fw-bolder' style={{ color: "#441a05" }}>READ OUR BLOG</div>
                <div className='text-start flex-grow-1 fw-bolder' style={{ color: "#441a05", fontSize: "47px" }}>Latest Articles</div>
            </div>
            <div className="d-flex Sajda" style={{ marginLeft: "7%" }}>
                <div className="w-50 col-lg-6 col-md-6 col-sm-12 Sajda1">
                    <img src="./image/sajda.jpeg" className="mb-4" width="90%" />
                    <div className="d-flex" style={{ width: "90%" }}>
                        <div className='fw-semibold' style={{ fontSize: "50px", color: "#441a05", marginLeft: "7%" }}>24</div>
                        <div className='text-start' style={{ marginLeft: "7%" }}>
                            <h3 className='fw-semibold' style={{ fontSize: "30px", color: "#441a05" }}>A Complete Guide For Ramadan Preperation 2022</h3>
                            <p style={{ color: "grey" }}>Q Proin faucibus nec mauris a sodales, sed elementum mi
                                tincidunt. Sed eget viverra egestas nisi in consequat.…</p>
                            <div className="d-flex mt-4" style={{ color: "grey" }}>
                                <p>0 likes .</p>
                                <p className="mx-3">0 comments</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 Sajda2">
                    <div className="row flex-direction-column Sajda2c" style={{ width: "95%" }}>
                        <div>
                            <div className="card mb-3" style={{ maxWidth: "85%", border: "none" }}>
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <img src="./image/pre-footer-masjid-img.jpeg" className="img-fluid Images" alt="..." />
                                    </div>
                                    <div className="col-md-8 col-sm-8 ">
                                        <div className="card-body text-start">
                                            <a href="#" style={{ color: "#441a05" }} className='fw-bolder'>ISLAM</a>
                                            <a style={{ color: "grey" }} className='mx-3'>24-Apr-2022</a>
                                            <h6 style={{ color: "#441a05", width: "100%", fontSize: "4vh" }} className='fw-bolder Sajda-h'>Saving Yemen: Millions of Families Got Your Support</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card mb-3" style={{ maxWidth: "85%", border: "none" }}>
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <img src="./image/pre-footer-masjid-img.jpeg" className="img-fluid Images" alt="..." />
                                    </div>
                                    <div className="col-md-8 col-sm-8 ">
                                        <div className="card-body text-start">
                                            <a href="#" style={{ color: "#441a05" }} className='fw-bolder'>ISLAM</a>
                                            <a style={{ color: "grey" }} className='mx-3' >24-Apr-2022</a>
                                            <h6 style={{ color: "#441a05", width: "100%", fontSize: "4vh" }} className='fw-bolder Sajda-h'>Free Digital Islamic Calendar for 2022</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card mb-3" style={{ maxWidth: "85%", border: "none" }}>
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <img src="./image/pre-footer-masjid-img.jpeg" className="img-fluid Images" alt="..." />
                                    </div>
                                    <div className="col-md-8 col-sm-8">
                                        <div className="card-body text-start">
                                            <a href="#" style={{ color: "#441a05" }} className='fw-bolder'>ISLAM</a>
                                            <a style={{ color: "grey" }} className='mx-3' >24-Apr-2022</a>
                                            <h6 style={{ color: "#441a05", width: "100%", fontSize: "4vh" }} className='fw-bolder Sajda-h'>Benefits of Learning Holy Quran Online</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Section6