import React from 'react'
import { Link } from "react-router-dom";
import './Style.css'

function Contact() {
    return (
        <div>
            <nav className="navbar navbar-expand-lg position-relative" style={{ background: 'linear-gradient(to top, rgba(0, 0, 0, 10), rgba(0, 0, 0, 50))', zIndex: "1", width: "100%" }}>
                <div className="container-fluid" style={{ marginLeft: "8%" }}>
                    <img src="./image/Logo-Islam.webp" alt="logo" />
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginLeft: "9%" }}>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 my-4">
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/home">Faqs</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/">Blog</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/">Donaton</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/">Shop</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/contact">Contact</Link>
                            </li>
                        </ul>
                        <div className="btn border-success green-btn">search</div>
                    </div>
                </div>
            </nav>
            <div className='position-relative d-flex justify-content-center' style={{ backgroundColor: "rgb(249, 245, 234)", height: "500px" }}>
                <div className="mt-100 fw-bold">
                    <div style={{ fontSize: "70px", color: "#441a05" }}>Contact</div>
                    <div className="text-center" >Home-Contact</div>
                </div>
            </div>
            <div className='position-relative d-flex justify-content-center Contact-flex1-H' style={{ backgroundColor: "white", height: "100vh" }}>
                <div className="mt-90 d-flex Contact-flex1" >
                    <div className="w-50 text-center Contact-flex1-C">
                        <img src="./image/mosq.jpeg" alt="logo" style={{ width: "100%" }} />
                        <p style={{ color: "#441a05" }} className="fw-bolder fs-3" >St. Leroy Church</p>
                        <p style={{ color: "grey" }} className="fs-5 px-5" >30 Coleman Street, 350
                            London, UK EC2R 5AL</p>
                    </div>
                    <div className="w-50 text-center Contact-flex1-C">
                        <img src="./image/mosq.jpeg" alt="logo" style={{ width: "100%" }} />
                        <p style={{ color: "#441a05" }} className="fw-bolder fs-3" >St. Henry Church</p>
                        <p style={{ color: "grey" }} className="fs-5 px-5" >121 W 27th Street, 48
                            New York, NY, USA 10001</p>
                    </div>
                    <div className="w-50 text-center Contact-flex1-C">
                        <img src="./image/mosq.jpeg" alt="logo" style={{ width: "100%" }} />
                        <p style={{ color: "#441a05" }} className="fw-bolder fs-3" >St. Peter Church</p>
                        <p style={{ color: "grey" }} className="fs-5 px-5" >21 Verneuil Street, 136
                            Paris, Fr, 75007 USA 10001</p>
                    </div>
                </div>
            </div>
            <div className='position-relative Contact-flex-form-H' style={{ backgroundColor: "rgb(249, 245, 234)", height: "700px" }}>
                <div className="fw-bold mx-5 pt-5 d-flex Contact-flex-form">
                    <div style={{ width: "35%", marginLeft: "7%" }} className='Contact-flex-form-c1'>
                        <div style={{ fontSize: "20px", color: "#441a05" }}>Contact Us</div>
                        <div style={{ fontSize: "45px", color: "#441a05" }} className='Contact-flex-form-c1H'>Have a Cool Project? Get in touch!</div>
                    </div>
                    <div style={{ width: "40%", height: "550px", backgroundColor: "white", marginLeft: "10%" }} className='Contact-flex-form-c2'>
                        <form className="form">
                            <div className="m-5 position-relative d-flex align-items-center ">
                                <input type="email" className="form-control" placeholder="Name" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            </div>
                            <div className="m-5 position-relative d-flex align-items-center">
                                <input type="password" className="form-control" placeholder="Phone" id="exampleInputPassword1" />
                            </div>
                            <div className="m-5 position-relative d-flex align-items-center">
                                <input type="password" className="form-control" placeholder="Email Address" id="exampleInputPassword1" />
                            </div>
                            <div className="m-5 position-relative d-flex align-items-center">
                                <input type="password" className="form-control" placeholder="Subject" id="exampleInputPassword1" />
                            </div>
                            <div className="m-5 position-relative d-flex justify-content-start">
                                <input type="password" className="form-control last-input position-relative d-flex align-items-center" placeholder="How can we help you? Feel free to get in touch!
                            " id="exampleInputPassword1" />
                            </div>
                            <button type="submit" className="px-4 py-2 text-light" style={{ backgroundColor: "#daa23d", border: "none", marginLeft: "50px" }}>Get in touch</button>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <div className='position-relative d-flex'>
                    <img src="./image/section-6-background.jpg" className='position-absolute Contact-flex1-foot-bg' style={{ width: "100%", height: "550px" }} />
                </div>
                {/* least */}
                <div className=' position-relative w-100' style={{ marginTop: "10%" }}>
                    <div className='position-absolute d-flex Contact-flex1-foot' style={{ width: "100%", borderBottom: "1px solid grey", paddingBottom: "6%" }}>
                        <div className='Contact-flex1-foot0'>
                            <img src="./image/Logo-Islam.webp" style={{ margin: "0% 40%" }} />
                        </div>
                        <div style={{ marginLeft: "22%", width: "70%" }} className='text-start Contact-flex1-foot1'>
                            <h5 className='text-light fw-bolder mb-5'>Office</h5>
                            <div className='fs-5' style={{ color: "#cac2cc" }}>Germany - <br /> 785 15h Street, Office 478 Berlin, De 81566 <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}> abc@gmail.com</a> <br /><br />
                                <span className='text-light'>+1 840 841 25 69</span></div>
                        </div>
                        <div style={{ marginLeft: "10%", width: "20%" }} className='text-center Contact-flex1-foot2'>
                            <h5 className='text-light fw-bolder mb-5'>Link</h5>
                            <div className='text-light'>
                                <a href="#" style={{ color: "#cac2cc" }}>Home</a> <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}>Services</a> <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}>About Us</a> <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}>Features</a> <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}>Contact</a> <br />
                            </div>
                        </div>
                        <div style={{ marginLeft: "10%", width: "100%" }} className='text-start Contact-flex1-foot3'>
                            <h5 className='text-light fw-bolder mb-5'>Get In Touch</h5>
                            <div>
                                <div className="mt-4">
                                    <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px" }}><i className="fab fa-facebook-f mx-3"></i></a>
                                    <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px" }}><i className="fab fa-twitter mx-3"></i></a>
                                    <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px" }}><i className="fab fa-dribbble mx-3" style={{ color: "white" }}></i></a>
                                    <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px" }}><i className="fab fa-instagram mx-3" style={{ color: "white" }}></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='position-relative text-start mx-4 Contact-flex1-foot-last' style={{ marginTop: "515px", color: "#cac2cc" }}>ThemeREX© 2023. All Rights Reserved</div>
            </div>
        </div>
    )
}

export default Contact