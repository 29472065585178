import React from 'react'
import { Link } from "react-router-dom";

function Faqs() {

    return (
        <div>

            <nav className="navbar navbar-expand-lg position-relative" style={{ background: 'linear-gradient(to top, rgba(0, 0, 0, 10), rgba(0, 0, 0, 50))', zIndex: "1", width: "100%" }}>
                <div className="container-fluid" style={{ marginLeft: "8%" }}>
                    <img src="./image/Logo-Islam.webp" alt="logo" />
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginLeft: "9%" }}>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 my-4">
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/Faqs">Faqs</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/">Blog</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/">Donaton</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/">Shop</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link fs-5 mx-2" to="/contact">Contact</Link>
                            </li>
                        </ul>
                        <div className="btn border-success green-btn">search</div>
                    </div>
                </div>
            </nav>

            <div className='position-relative d-flex justify-content-center' style={{ backgroundColor: "rgb(249, 245, 234)", height: "440px" }}>
                <div className="mt-100 fw-bold">
                    <div style={{ fontSize: "70px", color: "#441a05" }}>FAQs</div>
                    <div className="text-center" >Faqs-FAQs</div>
                </div>
            </div>

            <div className='position-relative pt-5 Faq-Lay' style={{ backgroundColor: "white", height: "1000px", width: "100%" }}>
                <div className="my-5" >
                    <div className="w-50 text-center fw-bolder py-5" style={{ fontSize: "30px", color: "#441a05", borderBottom: "1px solid", marginLeft: "25%" }} data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">What Are the Basic Doctrines of the Church? +</div>
                    <div className="row">
                        <div className="col">
                            <div className="collapse multi-collapse" id="multiCollapseExample1">
                                <div className="card card-body text-center w-50" style={{ marginLeft: "25%" }}>
                                    Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur. Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-5" >
                    <div className="w-50 text-center fw-bolder py-5" style={{ fontSize: "30px", color: "#441a05", borderBottom: "1px solid", marginLeft: "25%" }} data-bs-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">How to Become a Church Member? +</div>
                    <div className="row">
                        <div className="col">
                            <div className="collapse multi-collapse" id="multiCollapseExample2">
                                <div className="card card-body text-center w-50" style={{ marginLeft: "25%" }}>
                                    Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur. Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-5" >
                    <div className="w-50 text-center fw-bolder py-5" style={{ fontSize: "30px", color: "#441a05", borderBottom: "1px solid", marginLeft: "25%" }} data-bs-toggle="collapse" href="#multiCollapseExample3" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">How Do I Get Involved in Missionary Work? +</div>
                    <div className="row">
                        <div className="col">
                            <div className="collapse multi-collapse" id="multiCollapseExample3">
                                <div className="card card-body text-center w-50" style={{ marginLeft: "25%" }}>
                                    Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur. Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-5" >
                    <div className="w-50 text-center fw-bolder py-5" style={{ fontSize: "30px", color: "#441a05", borderBottom: "1px solid", marginLeft: "25%" }} data-bs-toggle="collapse" href="#multiCollapseExample4" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">Do You Conduct Worship Online? +</div>
                    <div className="row">
                        <div className="col">
                            <div className="collapse multi-collapse" id="multiCollapseExample4">
                                <div className="card card-body text-center w-50" style={{ marginLeft: "25%" }}>
                                    Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur. Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='position-relative d-flex'>
                    <img src="./image/section-6-background.jpg" className='position-absolute Contact-flex1-foot-bg' style={{ width: "100%", height: "550px" }} />
                </div>
                {/* least */}
                <div className=' position-relative w-100' style={{ marginTop: "10%" }}>
                    <div className='position-absolute d-flex Contact-flex1-foot' style={{ width: "100%", borderBottom: "1px solid grey", paddingBottom: "6%" }}>
                        <div className='Contact-flex1-foot0'>
                            <img src="./image/Logo-Islam.webp" style={{ margin: "0% 40%" }} />
                        </div>
                        <div style={{ marginLeft: "22%", width: "70%" }} className='text-start Contact-flex1-foot1'>
                            <h5 className='text-light fw-bolder mb-5'>Office</h5>
                            <div className='fs-5' style={{ color: "#cac2cc" }}>Germany - <br /> 785 15h Street, Office 478 Berlin, De 81566 <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}> abc@gmail.com</a> <br /><br />
                                <span className='text-light'>+1 840 841 25 69</span></div>
                        </div>
                        <div style={{ marginLeft: "10%", width: "20%" }} className='text-center Contact-flex1-foot2'>
                            <h5 className='text-light fw-bolder mb-5'>Link</h5>
                            <div className='text-light'>
                                <a href="#" style={{ color: "#cac2cc" }}>Home</a> <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}>Services</a> <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}>About Us</a> <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}>Features</a> <br /><br />
                                <a href="#" style={{ color: "#cac2cc" }}>Contact</a> <br />
                            </div>
                        </div>
                        <div style={{ marginLeft: "10%", width: "100%" }} className='text-start Contact-flex1-foot3'>
                            <h5 className='text-light fw-bolder mb-5'>Get In Touch</h5>
                            <div>
                                <div className="mt-4">
                                    <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px" }}><i className="fab fa-facebook-f mx-3"></i></a>
                                    <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px" }}><i className="fab fa-twitter mx-3"></i></a>
                                    <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px" }}><i className="fab fa-dribbble mx-3" style={{ color: "white" }}></i></a>
                                    <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px" }}><i className="fab fa-instagram mx-3" style={{ color: "white" }}></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='position-relative text-start mx-4 Contact-flex1-foot-last' style={{ marginTop: "515px", color: "#cac2cc" }}>ThemeREX© 2023. All Rights Reserved</div>
            </div>
        </div>
    )
}

export default Faqs