import React from 'react'
import './Style.css'

function Section4() {
    return (
        <div className='position-relative d-flex justify-content-center'>
            <div>
                <img src="./image/section-6-background.jpg" className='position-absolute Big-text-img' style={{ width: "100%", height: "600px" }} />
            </div>
            <div className='position-relative'>
                <div style={{ marginTop: "10%" }}>
                    <div className='text-light fw-bolder' >UPCOMING EVENT</div>
                    <div className='text-light fw-bolder w-75 Big-text' style={{ marginLeft: "15%", fontSize: "50px" }}>Happy Muharram Day or Islamic New Year on 19th July 2023</div>
                </div>
                <button className='fw-semibold my-5' style={{ border: "none", width: "48vh", height: "8vh", backgroundColor: "#daa23d", color: "white", }}>VIEW ALL EVENTS</button>
            </div>
        </div>

    )
}

export default Section4