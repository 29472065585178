import React from 'react'
import './Style.css'

function Section2() {
    return (
        <div>
            <img src="./image/decor-layer-lower.webp" alt="layer" className='position-relative w-100 img-fluid' style={{ marginTop: "-6vh" }} />

            <div className='my-5 d-flex justify-content-center positon-relative Sec2'>
                <img src="./image/section-3.avif" className='positon-absolute Sec2-img' style={{ width: "500px" }} />
                <div className="position-absolute Sec2-1" style={{ width: "400px", textAlign: "left", padding: "65px", border: "none", backgroundColor: "rgb(249, 245, 234)", margin: "350px 740px 0px 0px" }}>
                    <div className="card-body">
                        <h3 style={{ color: "#441a05" }} className='fs-1'>Prayer Times</h3>
                        <p style={{ color: "#9d9087" }}>Consectetur adipiscing elit, sed do eiusmod tempor.</p>
                        <li style={{ color: "#daa23d" }}><span style={{ color: "#9d9087" }}>Mon-Fri: 9 AM – 6 PM</span></li>
                        <li style={{ color: "#daa23d" }}><span style={{ color: "#9d9087" }}>Saturday: 9 AM – 4 PM</span></li>
                        <li style={{ color: "#daa23d" }}><span style={{ color: "#9d9087" }}>Sunday: Closed</span></li>
                    </div>
                </div>
                <div className="position-absolute Sec2-2" style={{ width: "30%", height: "360px", textAlign: "left", padding: "80px 0px 0px 50px", border: "none", backgroundColor: "#daa23d", margin: "90px 0px  0px 50%" }}>
                    <div className="card-body" style={{ width: "100%" }}>
                        <h3 className='fs-1 text-light'>Islamic Mission</h3>
                        <p className='text-light w-75'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <button className="my-4 fs-5 yellow-btn" >ABOUT US</button>
                    </div>
                </div>
            </div>

            <div className='position-relative d-flex justify-content-center Sec2-3'>
                <div className='position-absolute my-4 Sec-3p'>OUR SERVICES</div>
                <div className='position-absolute fs-1 fw-semibold w-50 Sec-3' style={{ marginTop: "7%", color: "#441a05" }}>We Honor Wisdom of Holy Quran with Faith and Hope</div>
            </div>

            <div className='position-relative d-flex justify-content-center Div-Icon1' style={{ marginTop: "17%", marginLeft:"3%" }}>
                <div className="service-cards w-25 Div-Icon2">
                    <div className='my-5' style={{ width: "80%" }}>
                        <i className="fa-solid fa-book fa-5x" style={{ color: "#81fae0" }}></i>
                    </div>
                    <div style={{ width: "80%" }}>
                        <h4 style={{ color: "#441a05" }}>Knowledge</h4>
                        <p style={{ color: "#9d9087" }}>Consectetur adipiscing elit, sed do eiusmod tempo.</p>
                    </div>
                </div>
                <div className="service-cards w-25 Div-Icon2">
                    <div className='my-5' style={{width:"80%"}}>
                        <i className="fa-solid fa-star-and-crescent fa-5x" style={{ color: "#81fae0" }}></i>
                    </div>
                    <div style={{width:"80%"}}>
                    <h4 style={{ color: "#441a05" }}>Spirituality</h4>
                    <p style={{ color: "#9d9087" }}>Consectetur adipiscing elit, sed do eiusmod tempo.</p>
                    </div>
                </div>
                <div className="service-cards w-25 Div-Icon2">
                    <div className='my-5' style={{width:"80%"}}>
                        <i className="fa-solid fa-bahai fa-5x" style={{ color: "#81fae0" }}></i>
                    </div>
                    <div style={{width:"80%"}}>
                    <h4 style={{ color: "#441a05" }}>Fundraising</h4>
                    <p style={{ color: "#9d9087" }}>Consectetur adipiscing elit, sed do eiusmod tempo.</p>
                    </div>
                </div>
                <div className="service-cards w-25 Div-Icon2">
                    <div className='my-5' style={{width:"80%"}}>
                        <i className="fa-solid fa-person-praying fa-5x" style={{ color: "#81fae0" }}></i>
                    </div>
                    <div style={{width:"80%"}}>
                    <h4 style={{ color: "#441a05" }}>Prayer</h4>
                    <p style={{ color: "#9d9087" }}>Consectetur adipiscing elit, sed do eiusmod tempo.</p>
                    </div>
                </div>
            </div>

            <button className='fw-semibold my-5 yellow-second-btn px-5' style={{width:"250px"}}>ALL SERVICES</button>

            <img src="./image/decor-layer-up.webp" alt="layer" className='position-relative w-100 img-fluid ' />

        </div>
    )
}

export default Section2