import React from 'react'

function SectionFOOT() {
    return (
        <div>
            <div className='my-5 position-relative d-flex'>
                <img src="./image/section-6-background.jpg" className='position-absolute Div-foot-bg' style={{ width: "100%", height: "950px" }} />
                <div>
                    <div style={{ margin: "10% 2%", paddingBottom: "3%", borderBottom: "1px solid grey" }} className='position-absolute'>
                        <div className='text-light fw-bolder' >ISLAMIC MISSIONARY</div>
                        <div className='text-light fw-bolder w-75 big-text-foot' style={{ marginLeft: "15%", fontSize: "50px" }}>We Maintain the Islamic Authenticity Around the World</div>
                        <button className='fw-semibold my-5 footer-btn'>About Us</button>
                    </div>
                </div>
            </div>
            {/* least */}
            <div className=' position-relative w-100 Div-foot-main' style={{ marginTop: "540px" }}>
                <div className='position-absolute d-flex Div-foot' style={{ width: "100%", borderBottom: "1px solid grey", paddingBottom: "6%" }}>
                    <div className='Div-foot-logo'>
                        <img src="./image/Logo-Islam.webp" style={{ margin: "0% 40%" }} />
                    </div>
                    <div style={{ marginLeft: "23%", width: "240px" }} className='text-start Div-foot1'>
                        <h5 className='text-light fw-bolder mb-4'>Office</h5>
                        <div className='fs-6' style={{ color: "#cac2cc", width: "230px" }}>Germany - <br /> 785 15h Street, Office 478 Berlin, De 81566 <br /><br />
                            <a href="#" style={{ color: "#cac2cc" }}> abc@gmail.com</a> <br /><br />
                            <span className='text-light'>+1 840 841 25 69</span></div>
                    </div>
                    <div style={{ marginLeft: "3%", width: "440px" }} className='text-center Div-foot2'>
                        <h5 className='text-light fw-bolder mb-4'>Link</h5>
                        <div className='text-light' >
                            <a href="#" style={{ color: "#cac2cc" }}>Home</a> <br /><br />
                            <a href="#" style={{ color: "#cac2cc" }}>Services</a> <br /><br />
                            <a href="#" style={{ color: "#cac2cc" }}>About Us</a> <br /><br />
                            <a href="#" style={{ color: "#cac2cc" }}>Features</a> <br /><br />
                            <a href="#" style={{ color: "#cac2cc" }}>Contact</a> <br />
                        </div>
                    </div>
                    <div style={{ marginLeft: "1%", width: "500px" }} className='text-start Div-foot3'>
                        <h5 className='text-light fw-bolder mb-4'>Get In Touch</h5>
                        <div>
                            <div className="mt-4">
                                <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px", margin: "0% 2%" }}><i className="fab fa-facebook-f mx-4 Icon"></i></a>
                                <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px", margin: "0% 2%" }}><i className="fab fa-twitter mx-3 Icon"></i></a>
                                <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px", margin: "0% 2%" }}><i className="fab fa-dribbble mx-3 Icon" style={{ color: "white" }}></i></a>
                                <a href='#' style={{ color: "white", border: "1px solid", borderRadius: "50%", fontSize: "30px", padding: "10px 0px", margin: "0% 2%" }}><i className="fab fa-instagram mx-3 Icon" style={{ color: "white" }}></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='position-relative text-start mx-4 Div-foot4' style={{ marginTop: "900px", color: "#cac2cc" }}>ThemeREX© 2023. All Rights Reserved</div>
        </div>
    )
}

export default SectionFOOT