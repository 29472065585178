import React from 'react'
import './Style.css'

function Section5() {
    return (
        <div className='Values-H' style={{ marginTop: "10%", padding: "10% 0% 10% 0%", backgroundColor: "rgb(249, 245, 234)", width: "100%" }}>
            <div className='position-relative d-flex Values' >
                <div className="position-absolute" style={{ width: "34%" }}>
                    <img src="./image/value-img-1.jpg" className='Values-img' alt="..." style={{ width: "490px", height: "600px", marginLeft: "25%" }} />
                </div>
                <div className="position-relative w-50 Values-1" style={{ marginLeft: "45%", marginTop: "1%" }}>
                    <div className="text-start w-100 px-5 Values-3">
                        <div className='fw-bolder my-4'>OUR VALUES</div>
                        <div style={{ fontSize: "7vh", color: "#441a05", width: "70vh" }} className='fw-semibold Values-2'>Allah Hears Your Prayers & Your Generosity</div>
                        <p style={{ width: "70vh", color: "grey" }} className='my-3 Values-2p'>Dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                            aspernatur aut odit aut fugit, sed quia consequuntur.</p>
                        {/* //bar */}
                        <div>
                            <div className="row mt-10 w-100">
                                <div className="d-flex Progress-W">
                                    <div className="progress blue mx-5">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right ">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">67%</div>
                                    </div>


                                    <div className="progress yellow">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">33%</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ borderTop: "1px solid grey", margin: "10% 14%" }} className='w-75'></div>

            <div className='d-flex justify-content-evenly Div-Icon-Y' style={{ marginTop: "10%" }}>
                <div className="col-lg-4 w-25 Div-Icon-Y1" style={{
                    textAlign: "center", alignItems: "center", background: "none",
                    border: "none"
                }}>
                    <i className="fa-solid fa-person-praying fa-7x value-icons" style={{ color: "#daa23d" }}></i>
                    <div className="card-body px-0 pb-0">
                        <h5 className="card-title mb-5 mt-3" style={{ fontSize: "57px", fontFamily: "Kumbh Sans, sans-serif" }}>
                            1000+
                        </h5>
                        <p className="card-text">
                            Some quick example text to build on the of the card's content.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 w-25 Div-Icon-Y1" style={{
                    textAlign: "center", alignItems: "center",
                    background: "none", border: "none"
                }}>
                    <i className="fa-solid fa-book-quran fa-7x value-icons" style={{ color: "#daa23d" }}></i>
                    <div className="card-body  px-0 pb-0">
                        <h5 className="card-title mb-5 mt-3" style={{ fontSize: "57px", fontFamily: "Kumbh Sans, sans-serif" }}>
                            458
                        </h5>
                        <p className="card-text">
                            Some quick example text to build on the of the card's content.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 w-25 Div-Icon-Y1" style={{ textAlign: "center", alignItems: "center", background: "none", border: "none" }}>
                    <i className="fa-solid fa-mosque fa-7x value-icons" style={{ color: "#daa23d" }}></i>
                    <div className="card-body   px-0 pb-0">
                        <h5 className="card-title mb-5 mt-3" style={{ fontSize: "57px", fontFamily: "Kumbh Sans, sans-serif " }}>
                            18
                        </h5>
                        <p className="card-text">
                            Some quick example text to build on the of the card's content.
                        </p>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default Section5