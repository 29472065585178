import React from 'react'

function Section1() {
    return (
        <div className='App position-relative' style={{
            marginTop: "675px", backgroundColor: "rgb(249, 245, 234)", paddingBottom:"10vh"
        }}>
            <div>
                <img className='position-relative img-fluid w-40' src='./image/place-islam.section two.webp' alt="image" />
                <h4 className='position-relative fw-normal fs-6 my-4' style={{ color: "#441a05", letterSpacing: '3px' }}>WELCOME TO ISLAMIC MISSIONARY</h4>
                <div className='w-75 m-auto fw-semibold lh-1' style={{ color: "#441a05", fontSize: "6.5vh" }}>A True Muslim, According to Prophet Muhammad, Invariably Shows Mercy and Gives Care.</div>
                <div className='position-relative fw-normal fs-5' style={{ color: "#441a05", marginTop: "30px" }}>Piter Bowman</div>
                <div className='position-relative fs-6 my-3' style={{ color: "grey" }}>Associate Scholar</div>
            </div>
        </div>
    )
}

export default Section1